import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const testData = [
  { id: 0, title: "Віддайте перевагу очній консультації", body: `Доброго дня!

  Віддайте перевагу очній консультації. Якщо це неможливо, то попередньо потрібно завантажити фото висипу або проблеми.
  Докладніше про це ви можете прочитати тут
  👉🏻 https://www.facebook.com/100001914964034/posts/4595808277159626/?d=n
  
  У домовлений час поговоримо через відеозвязок тут ( або у вайбері), або по телефону. Де вам зручно.
  Ціна консультації 400 грн, повторної- 200 грн
  Оплата після консультації`},
  { id: 1, title: "Я працюю у МЦ Св. Параскеви, Львів", body: `Доброго дня!

  Я працюю у МЦ Св. Параскеви, Львів.
  Задзвоніть за телефоном
  
  (067) 295-4-000
  
  Аббо через сайт
  
  https://www.medcenter.lviv.ua
  
  
  І оберіть зручний для вас час та локацію. Там вам дадуть відповіді на усі запитання.
  
  Я консультую очно та онлайн.` },
  { id: 2, title: "Нагадайте, будь ласка", body: `Доброго дня!

  Дякую, що написали.
  Нагадайте, будь ласка, зз яким діагнозом ви лікувались, чим і коли саме почали лікування, коли закінчили і чи продовжуєте.
  Як ефект від лікування? Якщо поганий, то опишіть чи хвороба відреагувала на лікування, і коли знову почалось поширення.
  ` },
  {id:3, title:"Гормони", body:"https://www.facebook.com/100001914964034/posts/3936080049799122/?extid=0&d=n"},
  {id:4, title:"Кропив‘янка", body:"https://www.facebook.com/100001914964034/posts/3975804475826679/?extid=0&d=n"},
  {id:5, title:"Лупа", body:"https://www.facebook.com/100001914964034/posts/4079045042169288/?extid=0&d=n"},
  {id:6, title:"Бородавки", body:"https://www.facebook.com/100001914964034/posts/4107531865987272/?extid=0&d=n"},
  {id:6, title:"Фурункули", body:"https://www.facebook.com/100001914964034/posts/4154538534619938/?extid=0&d=n"},
  {id:7, title:"Міжнародні протоколи", body:"https://www.facebook.com/100001914964034/posts/4173328662740925/?extid=0&d=n"},
  {id:8, title:"Травматизація родимок", body:"https://www.facebook.com/100001914964034/posts/4216937028380088/?extid=0&d=n"},
  {id:9, title:"Червоні прапорці у дерматології", body:"https://www.facebook.com/100001914964034/posts/4246897435384047/?extid=0&d=n"},
  {id:10, title:"Стажування Зальцбург", body:"https://www.facebook.com/100001914964034/posts/4290272807713176/?extid=0&d=n"},
  {id:11, title:"Джанотті- Крості", body:"https://www.facebook.com/100001914964034/posts/4305178586222598/?extid=0&d=n"},
  {id:12, title:"Проколювання вух", body:"https://www.facebook.com/100001914964034/posts/4407067802700342/?extid=0&d=n"},
  {id:13, title:"Про надмірну дезінфекцію", body:"https://www.facebook.com/100001914964034/posts/4499814683425653/?extid=0&d=n"},
  {id:14, title:"Фото висипки", body:"https://www.facebook.com/100001914964034/posts/4595808277159626/?d=n"},
  {id:15, title:"Правила посту", body:"https://www.facebook.com/groups/392576467981951/permalink/636350486937880/"},
  {id:16, title:"Алергічний контакний дерматит", body:"https://www.facebook.com/100001914964034/posts/4546179532122501/?d=n"},
  {id:17, title:"Англійська для лікарів", body:"https://www.facebook.com/100001914964034/posts/4607312596009194/?d=n"},
  {id:18, title:"Периоральний дерматит", body:"https://www.facebook.com/100001914964034/posts/4638584896215297/?d=n"},
  {id:19, title:"Мармуровість шкіри", body:"https://www.facebook.com/100001914964034/posts/4657556314318155/?d=n"},
  {id:20, title:"Вологі обгортання", body:"https://www.facebook.com/100001914964034/posts/4686258158114637/?d=n"},
  {id:21, title:"Стрижки у дітей", body:"https://www.facebook.com/100001914964034/posts/4698594710214315/?d=n"},
  {id:22, title:"Гормони , частина 2", body:"https://www.facebook.com/100001914964034/posts/4727023054038147/?d=n"},
  {id:23, title:"Сухість шкіри", body:"https://www.facebook.com/100001914964034/posts/4737337679673351/?d=n"},
  {id:24, title:"Пелюшковий дерматит", body:"https://www.facebook.com/100001914964034/posts/4753497131390739/?d=n"},
  {id:25, title:"Купання у травах", body:"https://www.facebook.com/100001914964034/posts/4773819402691845/?d=n"},
  {id:26, title:"Сухість рук", body:"https://www.facebook.com/100001914964034/posts/4802256776514774/?d=n"},
  {id:27, title:"Не перегрівайте дітей", body:"https://www.facebook.com/100001914964034/posts/4816959618377823/?d=n"},
  {id:28, title:"Чому виникає сухість на губах", body:"https://www.facebook.com/100001914964034/posts/4832017456872039/?d=n"},
  {id:29, title:"Сухість губ", body:"https://www.facebook.com/100001914964034/posts/4835841849822933/?d=n"},
  {id:30, title:"Забагато ліків", body:"https://www.facebook.com/100001914964034/posts/4855645404509244/?d=n"},
  {id:31, title:"Фолікулярний гіперкератоз", body:"https://www.facebook.com/100001914964034/posts/4874968909243560/?d=n"},
  {id:32, title:"Присипки", body:"https://www.facebook.com/100001914964034/posts/4901836503223467/?d=n"},
  {id:33, title:"Купання малюків", body:"https://www.facebook.com/100001914964034/posts/4927766063963844/?d=n"},
  {id:34, title:"Червоні щоки", body:"https://www.facebook.com/100001914964034/posts/4946873365386447/?d=n"},
  {id:35, title:"Атопічний дерматит", body:"https://www.facebook.com/100001914964034/posts/4972436279496822/?d=n"},
  {id:36, title:"Атопічний дерматит і вакцинація", body:"https://www.facebook.com/100001914964034/posts/5000893153317801/?d=n"},
  {id:37, title:"Хлорні ванни та АД", body:"https://www.facebook.com/100001914964034/posts/5027688887304894/?d=n"},
  {id:38, title:"Неонатальне акне", body:"https://www.facebook.com/100001914964034/posts/5057112107695905/?d=n"},
  {id:39, title:"Молочні кірочки", body:"https://www.facebook.com/100001914964034/posts/5085342004872915/?d=n"},
  {id:40, title:"АД, харчова алергія і дієта", body:"https://www.facebook.com/100001914964034/posts/5114097551997360/?d=n"},
  {id:41, title:"Чи можна митись при шкірних хворобах", body:"https://www.facebook.com/100001914964034/posts/5156412674432514/?d=n"},
  {id:42, title:"Імпетиго", body:"https://www.facebook.com/100001914964034/posts/5185638484843266/?d=n"},
  {id:43, title:"Чому виникають прищі", body:"https://www.facebook.com/100001914964034/posts/5211697238904057/?d=n"},
  {id:44, title:"Доказові методи лікування акне", body:"https://www.facebook.com/100001914964034/posts/5239278329479281/?d=n"},
  {id:45, title:"Чому виникають акне та вугрі", body:"https://www.facebook.com/100001914964034/posts/5272724692801311/?d=n"},
  {id:46, title:"Догляд за шкірою з акне", body:"https://www.facebook.com/100001914964034/posts/5306294326111014/?d=n"},
  {id:47, title:"Як зменшити свербіж", body:"https://www.facebook.com/100001914964034/posts/5336582716415508/?d=n"},
];
// const Note = (note) => (
//   <li class="list-group-item d-flex justify-content-between align-items-start">
//     <div class="ms-2 me-auto">
//       <div class="fw-bold"><Link to={"/edit/" + note.id}>{note.title}</Link></div>
//       {note.body}
//       </div>
//     <button onClick={() =>  navigator.clipboard.writeText(note.body)} class="btn btn-primary btn-lg">copy</button>
//   </li>
// );

const Note = (note) => (
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
       <button onClick={() =>  navigator.clipboard.writeText(note.body)} class="btn btn-primary">{note.title}</button>
    </div>
  </li>
);

function NoteList(notes) {
  <ol class="list-group list-group-numbered">
    {notes.map(note => <Note {...note} />)}
  </ol>
}

function App() {
  const notes = testData;
  return (
    <Router>
      <div>
        <Switch>
          {/* <Route path="/edit">
            <form>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Example textarea</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" value="ttt"></textarea>
              </div>
            </form>
          </Route>
          <Route path="/add">
            <form>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Example textarea</label>
                <textarea class="form-control" id="exampleFormControlTextarea1"></textarea>
              </div>
            </form>
          </Route> */}
          <Route path="/">
            {/* <nav class="navbar navbar-expand-lg fixed-bottom navbar-light" style={{backgroundColor: "#e3f2fd" }}>
              <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item" style={{ textAlign: "right" }}>
                      <Link to="/add">Add Note</Link>
                    </li>
                  </ul>
                   <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                  </form> 
                </div>
              </div>
            </nav> */}
            {/* <NoteList notes={notes} /> */}
            <ol class="list-group list-group-numbered">
              {notes.map(note => <Note {...note} />)}
            </ol>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
